import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import NavBar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Link } from "react-router-dom";
import Loading from "../../images/loading.gif";


const Subscribers = () => {
  const [toggle, setToggle] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const Toggle = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <div className="container-fluid min-vh-100 bg-light">
        <div className="row">
          {toggle && (
            <div className="col-lg-2 p-3 bg-white vh-100 position-fixed">
              <Sidebar />
            </div>
          )}
          {toggle && <div className="col-lg-2"></div>}
          <div className="col">
            <NavBar Toggle={Toggle} />
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center pt-5">
                {/* <Spinner animation="grow" variant="warning" /> */}
                <img src={Loading} alt="" width={25} />
              </div>
            ) : (
              <>
                <Link
                  to="/account/dashboard"
                  className="mb-3 text-decoration-none dashboardlink fw-bold"
                >
                  <i class="bi bi-arrow-left-circle-fill me-2"></i>Back
                </Link>

                <div className="mt-4">No data availabel</div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribers;
