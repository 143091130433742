import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

import Sidebar from "../../Components/Sidebar/Sidebar";
import NavBar from "../../Components/Navbar";
import { baseURL } from "../../utils/constant";
import { getCookie } from "../../utils/auth";
import Loading from "../../images/loading.gif"


const bearerToken = getCookie("bearerToken");
const headers = {
  Authorization: `Bearer ${bearerToken}`,
};


function EditPlan() {
  const { _id } = useParams();
  console.log(_id);
  const [toggle, setToggle] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    planName: "",
    planPrice: "",
    planDuration: "",
    planCredits: "",
  });

  const navigate = useNavigate()

  useEffect(() => {
    const fetchPlanDetails = async () => {
      setIsLoading(true);
      try {
        setIsLoading(true); 
        const response = await axios.get(`${baseURL}plan/getPlanByPlanId/${_id}`, {
          headers: { ...headers },
        });
        console.log("Response from API:", response.data);
        setFormData({
          planName: response.data.planName,
          planPrice: response.data.planPrice,
          planDuration: response.data.planDuration,
          planCredits: response.data.planCredits,
        });
        setIsLoading(false); 
      } catch (error) {
        console.error("Error fetching plan details:", error.message);
        
      } finally {
        setIsLoading(false); 
      }
    };
  
    fetchPlanDetails();
  }, [_id]);
  

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Prepare payload for updating plan
      const payload = {
        planId: _id,
        ...formData,
      };
      // Send POST request to update plan
      const res = await axios.post(`${baseURL}plan/editPlanByPlanId`, payload, {
        headers: { ...headers, "Content-Type": "application/json" },
      });
      console.log(res);
      Swal.fire("Updated!", "Plan successfully updated!", "success");
      navigate("/account/plans")
      setIsLoading(false); 
    } catch (error) {
      console.error("Error updating plan:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const Toggle = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <div className="container-fluid min-vh-100 bg-light">
        <div className="row">
          {toggle && (
            <div className="col-lg-2 p-3 bg-white vh-100 position-fixed">
              <Sidebar />
            </div>
          )}
          {toggle && <div className="col-lg-2"></div>}
          <div className="col">
            <NavBar Toggle={Toggle} />
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center pt-5">
                {/* <Spinner animation="grow" variant="warning" /> */}
                <img src={Loading} alt="" width={25} />
              </div>
            ) : (
              <div>
                <Container>
                <Link to="/account/plans" className="mb-3 text-decoration-none dashboardlink fw-bold"><i class="bi bi-arrow-left-circle-fill me-2"></i>Back</Link>

                  <h3>Edit Plan</h3>
                  <Row>
                    <Col
                      lg={12}
                      className=" d-flex justify-content-center align-items-center pt-5"
                    >
                      <Col lg={6} className="bg-white p-3">
                        <Form onSubmit={handleSubmit}>
                          <Form.Group className="mb-3" controlId="planName">
                            <Form.Label>Plan Name</Form.Label>
                            <Form.Control
                              type="text"
                              value={formData.planName}
                              onChange={handleChange}
                              placeholder="Enter plan name"
                            />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="planPrice">
                            <Form.Label>Plan Price</Form.Label>
                            <Form.Control
                              type="number"
                              value={formData.planPrice}
                              onChange={handleChange}
                              placeholder="Enter plan price"
                            />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="planDuration">
                            <Form.Label>Plan Duration</Form.Label>
                            <Form.Control
                              type="number"
                              value={formData.planDuration}
                              onChange={handleChange}
                              placeholder="Enter plan duration"
                            />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="planCredits">
                            <Form.Label>Plan Credits</Form.Label>
                            <Form.Control
                              type="number"
                              value={formData.planCredits}
                              onChange={handleChange}
                              placeholder="Enter plan credits"
                            />
                          </Form.Group>
                          <Button variant="primary" type="submit" on>
                            Update
                          </Button>
                        </Form>
                      </Col>
                    </Col>
                  </Row>
                </Container>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default EditPlan;
