import Form from "react-bootstrap/Form";
import { Container, Row, Col, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../utils/constant";
import React, { useState, useEffect } from "react";
import Logo from "../../images/companylogo.png";
import LoginImage from "../../images/login.jpg";
import { isLogin, setAuthentication } from "../../utils/auth";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// console.log(baseURL);

export default function Login({ setisAuthenticated }) {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    const authenticate = async () => {
      if (await isLogin()) {
        navigate("/account/dashboard");
      }
    };
    authenticate();
  }, [navigate]);


  
  useEffect(() => {
    const checkAuthentication = async () => {
      if (await isLogin()) {
        setIsAuthenticated(true);
      }
    };
    checkAuthentication();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      email,
      password,
    };

    axios
      .post(`${baseURL}auth/login`, payload)
      .then((res) => {
        // console.log(res);
        setAuthentication(res.data.bearerToken);
        localStorage.setItem("token", res.data.bearerToken);
        navigate("/account/dashboard");

        setisAuthenticated(true);
        // toast("Login Successful", {
        //   position: "bottom-left",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const handlePasswordToggle = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <div className="vh-100 d-flex align-items-center justify-content-center bg-login bg-white">
        <Container fluid>
          <Row>
            {/* <Col lg={7} className="">
              <img src={LoginImage} alt="logo" className="img-fluid" />
            </Col> */}
            <Col lg={4} className="mx-auto">
              <Col lg={10} className="p-5 bg-white shadow rounded-3 mx-auto">
                {/* <Link to="https://smartqc.io">
                  <img
                    src={Logo}
                    width={150}
                    alt="logo"
                    className="mb-3 d-flex m-auto"
                  />
                </Link> */}
                {/* <p className="text-center mb-5">
                  Don't have an account?{" "}
                  <Link to="/register" className="text-decoration-none">
                    Sign up
                  </Link>
                </p> */}
                <h5 className="mb-4 text-center">Sign in</h5>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      className="border-1 border-bottom rounded-3"
                      name="email"
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2 position-relative">
                      <Form.Label>Password</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          type={passwordVisible ? "text" : "password"}
                          className="rounded-3 form-control-sm"
                          name="password"
                          onChange={handleInputChange}
                        />
                        {password && (
                          <button
                            type="button"
                            className="position-absolute end-0 top-50 translate-middle-y me-2 border-0 bg-transparent"
                            onClick={handlePasswordToggle}
                          >
                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                          </button>
                        )}
                      </div>
                    </Form.Group>
                  {/* <div className="d-flex justify-content-end">
                  <Link className="text-decoration-none" to="forgotpassword">
                    <span>Forgot password?</span>
                  </Link>
                  </div> */}
                  
                  <div className="d-grid gap-2 mt-3">
                    <Button
                      variant="btn btn-blue"
                      type="submit"
                      className="rounded-3"
                    >
                      Log in
                    </Button>
                  </div>
                </Form>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
