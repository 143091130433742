import React, { useState, useEffect } from "react";
import { getCookie } from "../../../utils/auth";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import { toast } from "react-toastify";
import { Form, Spinner } from "react-bootstrap";
import { useAppStrore } from "../../../appStore";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import Navbar from "../../../Components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import { MdOutlineAirplanemodeInactive } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import Loading from "../../../images/loading.gif";


export default function User({ _id }) {
  const [toggle, setToggle] = useState(true);
  const [userCount, setUserCount] = useState({
    inactiveUsers: 0,
    activeUsers: 0,
    provisionedUsers: 0,
    totalUsers: 0,
  });
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedEntries, setSelectedEntries] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [recordRange, setRecordRange] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const Toggle = () => {
    setToggle(!toggle);
  };

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${baseURL}dashboard/getStatusCountForUser`, { headers })
      .then((response) => {
        const { activeUsers, inactiveUsers, provisionedUsers, totalUsers } =
          response.data.data;
        setUserCount({
          activeUsers,
          inactiveUsers,
          provisionedUsers,
          totalUsers,
        });
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });

    // Fetch data for the first page on initial load
    fetchData(1);
  }, []);

  const handleEditUser = (_id) => {
    navigate(`/account/user/${_id}`); // Navigate to EditUser page with specific user ID
  };

  const handleDeactivate = (index) => {
    const updatedRows = [...rows];
    updatedRows[index].disabled = true;
    setRows(updatedRows);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleEntriesChange = (event) => {
    setSelectedEntries(parseInt(event.target.value));
    setCurrentPage(1); // Reset to first page when changing number of entries
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearch(query);
    setCurrentPage(1); // Reset to first page when searching
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, selectedEntries, search]);

  const fetchData = (page) => {
    setIsLoading(false);

    const payload = {
      page,
      start: (page - 1) * selectedEntries,
      length: selectedEntries,
      search,
      columns: [
        {
          data: "campaignName",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "recordCount",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "userId",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "dec",
        },
      ],
      search: {
        value: search,
        regex: false,
      },
    };
    axios
      .post(`${baseURL}user/getDataTableForUserList`, payload, { headers })
      .then((response) => {
        // console.log(response);
        setRows(
          response.data.data.map((row) => ({ ...row, disabled: false })) || []
        );
        setRecordRange(response.data.recordsRange);
        setTotalPages(response.data.totalPages);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(true);
        console.error("Error:", err);
        toast.error(err?.response?.data?.message || "An error occurred", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <>
      <div className="container-fluid min-vh-100 bg-light">
        <div className="row">
          {toggle && (
            <div className="col-lg-2 p-3 bg-white vh-100 position-fixed">
              <Sidebar />
            </div>
          )}
          {toggle && <div className="col-lg-2"></div>}
          <div className="col">
            <Navbar Toggle={Toggle} />
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center pt-5">
                {/* <Spinner animation="grow" variant="warning" /> */}
                <img src={Loading} alt="" width={25} />
              </div>
            ) : (
              <div>
                <div className="row mb-3">
                  <Link
                    to="/account/dashboard"
                    className="mb-3 text-decoration-none dashboardlink fw-bold"
                  >
                    <i class="bi bi-arrow-left-circle-fill me-2"></i>Back
                  </Link>
                  <div className="col-lg-8 my-auto">
                    <h5>Users</h5>
                  </div>
                  <div className="col-lg-2 ms-auto text-end">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => navigate("./adduser")}
                    >
                      New User
                    </button>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-3">
                    <div className="p-3 bg-white d-flex justify-content-start align-items-center rounded border_bottom">
                      <div>
                        <h5>{userCount.totalUsers}</h5>
                        <p className="mb-0 fw-bold">Total</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="p-3 bg-white d-flex justify-content-start align-items-center rounded border_bottom">
                      <div>
                        <h5>{userCount.activeUsers}</h5>
                        <p className="mb-0 fw-bold">Active</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="p-3 bg-white d-flex justify-content-start align-items-center rounded border_bottom">
                      <div>
                        <h5>{userCount.inactiveUsers}</h5>
                        <p className="mb-0 fw-bold">Inactive</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="p-3 bg-white d-flex justify-content-start align-items-center rounded border_bottom">
                      <div>
                        <h5>{userCount.provisionedUsers}</h5>
                        <p className="mb-0 fw-bold">Not verified</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div className="col-lg-1 ms-auto">
                    <div>
                      <Form.Select
                        value={selectedEntries}
                        onChange={handleEntriesChange}
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                      </Form.Select>
                    </div>
                  </div>
                </div>

                <table className="table table-hover table-sm table-responsive">
                  <thead>
                    <tr className="fw-bold">
                      <td>First Name</td>
                      <td>Last Name</td>
                      <td>Email</td>
                      <td>Role</td>
                      <td>Status</td>
                      <td>Credits</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td>{row.firstName}</td>
                        <td>{row.lastName}</td>
                        <td>{row.email}</td>
                        <td>{row.role?.roleName || "Role Not Specified"}</td>
                        <td>{row.status}</td>
                        <td>{row.credits}</td>
                        <td>
                          <button
                            className="btn btn-outline-info btn-sm me-2"
                            onClick={() => handleEditUser(row._id)} // Pass userId to handleEditUser function
                          >
                            {/* Edit */}
                            <MdEdit />
                          </button>
                          <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={() => handleDeactivate(index)} // Pass index to handleDeactivate function
                            disabled={row.disabled} // Disable the button if the row is disabled
                          >
                            {/* Deactivate */}
                            <MdVisibilityOff />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-lg-6 my-auto">{recordRange}</div>
                  <div className="col-lg-6 d-flex justify-content-end">
                    <Pagination>
                      {[...Array(totalPages)].map((_, index) => (
                        <Pagination.Item
                          key={index}
                          active={index + 1 === currentPage}
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                    </Pagination>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
