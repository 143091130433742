import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.png";

function Sidebar() {
  return (
    <div className="">
      <img src={Logo} className="img-fluid mb-3" alt="" />
      <div className="list-group list-group-flush">
        <Link
          to="/account/dashboard"
          className="list-group-item border-0 py-2 fw-bold"
        >
          <i className="bi bi-house-door-fill fs-6 me-3"></i>
          <span>Dashboard</span>
        </Link>
        <Link
          to="/account/campaigns"
          className="list-group-item border-0 py-2 fw-bold "
        >
          <i className="bi bi-speedometer2 fs-6 me-3 "></i>{" "}
          <span>Campaigns</span>
        </Link>

        <Link
          to="/account/user"
          className="list-group-item border-0 py-2 fw-bold"
        >
          <i className="bi bi-people-fill fs-6 me-3 "></i> <span>Users</span>
        </Link>
        <Link
          to="/account/roles"
          className="list-group-item border-0 py-2 fw-bold"
        >
          <i className="bi bi-clipboard-data fs-6 me-3 "></i> <span>Roles</span>
        </Link>
        <Link
          to="/account/payment"
          className="list-group-item border-0 py-2 fw-bold"
        >
          <i className="bi bi-credit-card fs-6 me-3 "></i> <span>Payments</span>
        </Link>
        <Link
          to="/account/plans"
          className="list-group-item border-0 py-2 fw-bold"
        >
          <i className="bi bi-coin fs-6 me-3 "></i> <span>Plans</span>
        </Link>
        <Link
          to="/account/subscribers"
          className="list-group-item border-0 py-2 fw-bold"
        >
          <i class="bi bi-person-check-fill fs-6 me-3"></i>
          <span>Subscribers</span>
        </Link>
      </div>
    </div>
  );
}
export default Sidebar;
