import React from "react";
import { getCookie } from "../../../utils/auth";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import Nav from "../../../Components/Navbar";
import { Link } from "react-router-dom";
import Loading from "../../../images/loading.gif";


function Dashboard({ Toggle }) {
  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const [dashboardData, setDashboardData] = useState({
    campaignCount: 0,
    userCount: 0,
    disqualifiedCount: 0,
    roleCount: 0,
    paymentCount: 0,
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${baseURL}dashboard/getDashboardCountForAdmin`, { headers })
      .then((response) => {
        const { campaignCount, userCount, roleCount, paymentCount } =
          response.data;
        setDashboardData({
          campaignCount,
          userCount,
          roleCount,
          paymentCount,
        });
        setIsLoading(false);
        // console.log(response);
      })
      .catch((err) => {
        // console.error(err?.response?.data?.message);
        setIsLoading(false);
      });
  }, []);
  return (
    <div className="">
      <Nav Toggle={Toggle} />
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center pt-5">
          {/* <Spinner animation="grow" variant="warning" /> */}
          <img src={Loading} alt="" width={25} />
        </div>
      ) : (
        // <div className="container-fluid">
        <div>
          <h5 className="mb-3">Dashboard</h5>
          <div className="row">
            <div className="col-lg-3 ">
              <Link
                to="/account/campaigns"
                className="mb-0 text-black dashboardlink"
              >
                <div className="p-3 bg_color shadow-sm d-flex justify-content-start align-items-center rounded hover-card border_bottom">
                  <div>
                    <h5>{dashboardData.campaignCount}</h5>
                    <p className="mb-0 fw-bold">Campaigns</p>
                  </div>
                  {/* <i className="bi bi-cart-plus p-3 fs-1"></i> */}
                </div>
              </Link>
            </div>
            <div className="col-lg-3">
              <Link
                to="/account/user"
                className="mb-0 text-black dashboardlink"
              >
                <div className="p-3 bg_color shadow-sm d-flex justify-content-start align-items-center rounded hover-card border_bottom">
                  <div>
                    <h5>{dashboardData.userCount}</h5>
                    <p className="mb-0 fw-bold">Users</p>
                  </div>
                  {/* <i className="bi bi-currency-dollar p-3 fs-1"></i> */}
                </div>
              </Link>
            </div>
            <div className="col-lg-3">
              <Link
                to="/account/roles"
                className="mb-0 text-black dashboardlink"
              >
                <div className="p-3 bg_color shadow-sm d-flex justify-content-start align-items-center rounded hover-card border_bottom ">
                  <div>
                    <h5>{dashboardData.roleCount}</h5>
                    <p className="mb-0 fw-bold">Roles</p>
                  </div>
                  {/* <i className="bi bi-truck p-3 fs-1"></i> */}
                </div>
              </Link>
            </div>
            <div className="col-lg-3 card-animate">
              <Link
                to="/account/payment"
                className="mb-0 text-black dashboardlink"
              >
                <div className="p-3 bg_color shadow-sm d-flex justify-content-start align-items-center rounded hover-card border_bottom ">
                  <div>
                    <h5>{dashboardData.paymentCount}</h5>{" "}
                    <p className="mb-0 fw-bold">Payments</p>
                  </div>
                  {/* <i className="bi bi-graph-up-arrow p-3 fs-1"></i> */}
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Dashboard;
