import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";

import {
  Route,
  BrowserRouter,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import Campaigns from "./pages/Account/Campaigns/Campaigns.jsx";
import Dashboard from "./pages/Account/Dashboard/Dashboard.jsx";
import Roles from "./pages/Account/Roles/Roles.jsx";
import CreateRole from "./pages/Account/Roles/CreateRoles.jsx";
import Payment from "./pages/Account/Payments/Payment.jsx";
import Plans from "./pages/Plans/Plans.jsx";
import Subscribers from "./pages/Subscribers/Subscribers.jsx";
import CreatePlan from "./pages/Plans/CreatePlan.jsx";
import EditPlan from "./pages/Plans/EditPlan.jsx";
import Login from "./pages/Login/Login.jsx";
import Register from "./pages/Register/Register";
import User from "./pages/Account/User/User.jsx";
import App from "./App.js";
import AddUser from "./pages/Account/User/Adduser.jsx";
import CampaignDetails from "./pages/Account/Campaigns/CampaignDetails.jsx";
// import AddUser from './pages/Account/Adduser/Adduser.jsx'
// import '../node_modules/bootstrap/dist/css/bootstrap.css'
// import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import EditUser from "./pages/Account/User/EditUser.jsx";

const PrivateRoute = ({ isAuthenticated, ...props }) => {
  const token = localStorage.getItem("token");
  return isAuthenticated && token ? <Outlet /> : <Navigate replace to="/" />;
};

const AppRoutes = () => {
  const [isAuthenticated, setisAuthenticated] = useState(() => {
    const token = localStorage.getItem("token");
    return !!token;
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    setisAuthenticated(!!token);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        <Route
          path="/"
          element={<Login setisAuthenticated={setisAuthenticated} />}
        />
        <Route path="/account/dashboard" element={<App />} />

        {/* <Route path="/account/dashboard" element={<PrivateRoute isAuthenticated={isAuthenticated}/>} >
      <Route path="/account/dashboard" element={<Dashboard />} />
      </Route> */}

        <Route
          path="/account/campaigns"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/campaigns/" element={<Campaigns />} />
        </Route>
        {/* <Route path="/account/newcampaign" element={<PrivateRoute isAuthenticated={isAuthenticated}/>} >
      <Route path="/account/newcampaign/" element={<NewCampaign />}  />
      </Route> */}
        <Route
          path="/account/campaigns/:_id"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/campaigns/:_id" element={<CampaignDetails />} />
        </Route>

        <Route
          path="/account/roles"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/roles" element={<Roles />} />
        </Route>

        <Route
          path="/account/roles/createrole"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/roles/createrole" element={<CreateRole />} />
        </Route>

        <Route
          path="/account/user"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/user" element={<User />} />
        </Route>

        <Route
          path="/account/user"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/user/adduser" element={<AddUser />} />
        </Route>

        <Route
          path="/account/user/:_id"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/user/:_id" element={<EditUser />} />
        </Route>

        <Route path="/account/payment" element={<Payment />} />
        <Route path="/account/plans" element={<Plans />} />
        <Route path="/account/subscribers" element={<Subscribers />} />
        <Route path="/account/plans/createPlan" element={<CreatePlan />} />
        <Route path="/account/plans/editPlan/:_id" element={<EditPlan />} />

        <Route path="/register" element={<Register />} />
        <Route path="/account/adduser" element={<AddUser />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.Fragment>
    <AppRoutes />
  </React.Fragment>
);
