import React, { useEffect, useState } from "react";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import NavBar from "../../../Components/Navbar";
import { Alert, Badge, Col, Container, Form, Pagination, Row, Spinner, Table } from "react-bootstrap";
import { getCookie } from "../../../utils/auth";
import { baseURL } from "../../../utils/constant";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../../../images/loading.gif";
import * as Icon from "react-bootstrap-icons";

function CampaignDetails() {
  const [idData, setIdData] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [status, setStatus] = useState("");
  const [campaginCount, setCampaginCount] = useState({});
  const [csvData, setCsvData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [dataId, setDataId] = useState("");
  const [selectedEntriesPerPage, setSelectedEntriesPerPage] = useState(50);
  const bearerToken = getCookie("bearerToken");

  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const { _id } = useParams();

  const Toggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${baseURL}campaign/getCampaignByCampaignIdForAdmin/${_id}`, {
          headers: headers,
        });
        if (Array.isArray(response.data.leadFields)) {
          setStatus(response.data);
          setCsvData(response.data.qualifiedData);
        } else {
          setIdData([response.data]);
        }
        setIsLoading(false);
      } catch (err) {
        toast(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false);
      }
    };

    fetchData();
  }, [_id]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = csvData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(csvData.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleEntriesPerPageChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    setSelectedEntriesPerPage(selectedValue);
    setCurrentPage(1);
    setItemsPerPage(selectedValue);
  };

  // const DownloadFile = (_id) => {
  //   if (!_id) {
  //     toast.error("Data ID is missing", {
  //       position: "bottom-left",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //     return;
  //   }
  
  //   axios
  //     .get(`${baseURL}campaign/downloadSampleImportFileByCampaignId/${_id}`, {
  //       headers: { ...headers },
  //       responseType: "blob",
  //     })
  //     .then((response) => {
  //       const blob = new Blob([response.data], {
  //         type: response.headers["content-type"],
  //       });
  
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = url;
  
  //       const contentDisposition = response.headers["content-disposition"];
  //       const filenameMatch = contentDisposition && contentDisposition.match(/filename=(.+)$/);
  //       const filename = filenameMatch ? filenameMatch[1] : `sample_import_${_id}.csv`;
  
  //       link.setAttribute("download", filename);
  
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       window.URL.revokeObjectURL(url);
  
  //       toast.success(response.data.message, {
  //         position: "bottom-left",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error(err.response?.data?.message || "Failed to download file", {
  //         position: "bottom-left",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     });
  // };

  useEffect(() => {
    axios
      .get(`${baseURL}dashboard/getStatusCountForLeadsByCampaignId/${_id}`, {
        headers: { ...headers },
      })
      .then((response) => {
        setIsLoading(false);
        setCampaginCount(response.data.data);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, []);

  return (
    <>
      <Container fluid className="min-vh-100 bg-light">
        <Row>
          {toggle && (
            <Col lg={2} className="p-3 bg-white vh-100 position-fixed">
              <Sidebar />
            </Col>
          )}
          {toggle && <Col lg={2}></Col>}
          <Col lg={toggle ? 10 : 12}>
            <NavBar Toggle={Toggle} />{" "}
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center vh-100">
                <img src={Loading} alt="" width={25} />
              </div>
            ) : (
              <div>
                <div className="mb-3">
                  <div className="text-center">
                    <h5 className="mt-4 fw-bold">{status.campaignName}</h5>
                  </div>
                  <div>
                    <Link
                      to="/account/campaigns"
                      className="mb-3 text-decoration-none dashboardlink fw-bold"
                    >
                      <i className="bi bi-arrow-left-circle-fill me-2"></i> Back
                      to Campaign
                    </Link>
                  </div>
                  <div className="text-center">
                    <Badge
                      className={
                        status.status === "Complete"
                          ? "bg-success"
                          : "bg-warning text-dark"
                      }
                    >
                      {status.status}
                    </Badge>
                  </div>
                </div>
                <hr />

                <Row className=" mt-4 mb-4">
                  <Col lg={3}>
                    <div className="p-3 bg-white mt-4 rounded-4 hover-card border_bottom ">
                      <div className="text-center">
                        <p className="mb-1 fw-bold">Total </p>
                        <h5>{campaginCount.totalLeads}</h5>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="p-3 bg-white mt-4 rounded-4 hover-card border_bottom ">
                      <div className="text-center">
                        <p className="mb-0 fw-bold">Pending</p>
                        <h5>{campaginCount.pendingLeads}</h5>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="p-3 bg-white mt-4 rounded-4 hover-card border_bottom ">
                      <div className="text-center">
                        <p className="mb-0 fw-bold">Qualified</p>
                        <h5>{campaginCount.qualifiedLeads}</h5>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="p-3 bg-white mt-4 rounded-4 hover-card border_bottom ">
                      <div className="text-center">
                        <p className="mb-0 fw-bold">Disqualified</p>
                        <h5>{campaginCount.disqualifiedLeads}</h5>
                      </div>
                    </div>
                  </Col>
                </Row>

                {csvData.length > 0 ? (
                  <>
                    {status.status === "Pending" ? (
                      <Container fluid className="mt-1">
                        {/* <Alert className="text-start" variant="warning">
                          <Icon.ExclamationTriangleFill className="text-warning me-2" />
                          Please take a look at the csv file format{" "}
                          <a
                            className="text-decoration-none cursor-pointer "
                            onClick={() => DownloadFile(_id)}
                          >
                            here{" "}
                          </a>
                          before importing the contacts.
                        </Alert> */}
                      </Container>
                    ) : (
                      <>
                        <Row className=" mb-3 mt-5 ">
                          <Col lg={8} className="">
                            <Form.Control
                              type="text"
                              className="form-control ms-4 rounded-2 "
                              placeholder="Search"
                              onChange={(e) => setSearchTerm(e.target.value)}
                            />
                          </Col>
                          <Col lg={1}/>
                          <Col lg={2} className="">
                            <div>
                              <Form.Select
                                value={selectedEntriesPerPage}
                                onChange={handleEntriesPerPageChange}
                              >
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={500}>500</option>
                              </Form.Select>
                            </div>
                          </Col>
                        </Row>
                        <div className=" rounded-5 p-3 bg-white" >
                          <Table hover responsive>
                            <thead className="text_orange">
                              <tr className="">
                                <th className="text-black">First Name</th>
                                <th className="text-black">Last Name</th>
                                <th className="text-black">Company Name</th>
                                <th className="text-black">Job Title</th>
                                <th className="text-black">Phone Number</th>
                                <th className="text-black">Email</th>
                                <th className="text-black">Industry</th>
                                <th className="text-black">Status</th>
                                {/* <th className="text-black"></th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems
                                .filter((val) => {
                                  if (searchTerm === "") {
                                    return val;
                                  } else if (
                                    val.first_name
                                      .toLowerCase()
                                      .includes(searchTerm.toLowerCase()) ||
                                    val.email
                                      .toLowerCase()
                                      .includes(searchTerm.toLowerCase()) ||
                                    val.phone_number
                                      .toLowerCase()
                                      .includes(searchTerm.toLowerCase())
                                  ) {
                                    return val;
                                  }
                                })
                                .map((val, i) => (
                                  <tr key={i}>
                                  <td>{val.first_name}</td>
                                  <td>{val.last_name}</td>
                                  <td className="text-truncate">
                                    <div className="text-truncate">{val.company_name}</div>
                                  </td>
                                  <td>{val.job_title}</td>
                                  <td>{val.phone_number}</td>
                                  <td>{val.email}</td>
                                  <td>{val.industry}</td>
                                  <td>{val.status}</td>
                           
                                
                                    {/* <td>
                                    <Link
                                      to={`/account/campaigns/${status._id}/${val._id}`}
                                      className="btn btn-primary btn-sm me-2"
                                    >
                                      View
                                    </Link>
                                  </td> */}
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </div>

                        <div className="d-flex justify-content-end">
                          <Pagination>
                            <Pagination.Prev
                              onClick={() =>
                                setCurrentPage((prev) =>
                                  prev <= 1 ? prev : prev - 1
                                )
                              }
                              disabled={currentPage === 1}
                            />
                            {Array.from({ length: totalPages }, (_, i) => {
                              if (
                                i + 1 === 1 ||
                                i + 1 === totalPages ||
                                Math.abs(currentPage - (i + 1)) <= 1
                              ) {
                                return (
                                  <Pagination.Item
                                    key={i + 1}
                                    active={i + 1 === currentPage}
                                    onClick={() => paginate(i + 1)}
                                  >
                                    {i + 1}
                                  </Pagination.Item>
                                );
                              } else if (
                                (i === 0 && currentPage > 2) ||
                                (i === totalPages - 1 &&
                                  currentPage < totalPages - 1)
                              ) {
                                return <Pagination.Ellipsis key={i} />;
                              }
                              return null;
                            })}
                            <Pagination.Next
                              onClick={() =>
                                setCurrentPage((prev) =>
                                  prev >= totalPages ? prev : prev + 1
                                )
                              }
                              disabled={currentPage === totalPages}
                            />
                          </Pagination>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div className="d-flex justify-content-center align-items-center ">
                    <p>No data found.</p>
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CampaignDetails;
  



