import React, { useEffect, useState } from "react";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import NavBar from "../../../Components/Navbar";
import { Form, Spinner } from "react-bootstrap";
import { baseURL } from "../../../utils/constant";
import { toast } from "react-toastify";
import axios from "axios";
import { getCookie } from "../../../utils/auth";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../../images/loading.gif";

function NewCampaign() {
  const [toggle, setToggle] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [campaginCount, setCampaginCount] = useState({});
  const [campaigns, setCampaigns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [recordRange, setRecordRange] = useState([]);
  const [selectedEntries, setSelectedEntries] = useState(20);
  const [search, setSearch] = useState("");
  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const navigate = useNavigate();
  const Toggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    axios
      .get(`${baseURL}dashboard/getStatusCountForCampaign`, {
        headers: { ...headers },
      })
      .then((response) => {
        // console.log(response);
        setIsLoading(false);
        const {
          newCampaigns,
          totalCampaigns,
          completeCampaigns,
          inProgressCampaigns,
        } = response.data.data;

        setCampaginCount({
          newCampaigns,
          totalCampaigns,
          completeCampaigns,
          inProgressCampaigns,
        });
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, []);

  const tableData = (page, start, length, search) => {
    const payload = {
      page: page,
      start: start,
      length: length,
      columns: [
        {
          data: "campaignName",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "recordCount",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "userId",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "dec",
        },
      ],
      search: {
        value: search,
        regex: false,
      },
    };

    axios
      .post(`${baseURL}campaign/getDataTableForCampaign`, payload, {
        headers: { ...headers },
      })
      .then((response) => {
        const sortedCampaigns = response.data.data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        // console.log(response);
        setIsLoading(false);
        setCampaigns(response.data.data);
        setRecordRange(response.data.recordsRange);
        setTotalPages(response.data.totalPages);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Error:", err);
        toast(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  useEffect(() => {
    tableData(1, 0, selectedEntries, "");
  }, []);

  const handlePageChange = (page, selectedLength) => {
    setSelectedEntries(selectedLength); // Update selectedEntries state
    setCurrentPage(page); // Update currentPage state
    tableData(page, (page - 1) * selectedLength, selectedLength, search); // Call tableData with the new page and other parameters
  };

  const handleEntriesChange = (event) => {
    const selectedLength = parseInt(event.target.value);
    handlePageChange(1, selectedLength); // Reset to first page when changing number of entries
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearch(query);
    setCurrentPage(1);
    if (query.trim() !== "") {
      tableData(1, 0, selectedEntries, query); // Pass selectedEntries instead of a fixed value
    } else {
      tableData(1, 0, selectedEntries, ""); // Pass selectedEntries instead of a fixed value
    }
  };
  return (
    <>
      <div className="container-fluid min-vh-100 bg-light">
        <div className="row ">
          {toggle && (
            <div className="col-lg-2 p-3 bg-white vh-100 position-fixed">
              <Sidebar />
            </div>
          )}
          {toggle && <div className="col-lg-2 "></div>}
          <div className="col">
            <NavBar Toggle={Toggle} />{" "}
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center pt-5">
                {/* <Spinner animation="grow" variant="warning" /> */}
                <img src={Loading} alt="" width={25} />
              </div>
            ) : (
              <>
                <Link
                  to="/account/dashboard"
                  className="mb-3 text-decoration-none dashboardlink fw-bold"
                >
                  <i class="bi bi-arrow-left-circle-fill me-2"></i>Back
                </Link>
                <h5 className="mb-3 mt-4 fw-bold">Campaigns</h5>
                <div className="row mb-3">
                  <div className="col-lg-3">
                    <div className="p-3 bg-white d-flex justify-content-start align-items-center rounded border_bottom">
                      <div>
                        <h5>{campaginCount.totalCampaigns}</h5>
                        <p className="mb-0 fw-bold">Total</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="p-3 bg-white d-flex justify-content-start align-items-center rounded border_bottom">
                      <div>
                        <h5>{campaginCount.newCampaigns}</h5>
                        <p className="mb-0 fw-bold">New</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="p-3 bg-white d-flex justify-content-start align-items-center rounded border_bottom">
                      <div>
                        <h5>{campaginCount.inProgressCampaigns}</h5>
                        <p className="mb-0 fw-bold">In progress</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="p-3 bg-white d-flex justify-content-start align-items-center rounded border_bottom">
                      <div>
                        <h5>{campaginCount.completeCampaigns}</h5>
                        <p className="mb-0 fw-bold">Complete</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={search}
                      onChange={handleSearchChange} // Pass handleSearchChange directly
                    />
                  </div>
                  <div className="col-lg-1 ms-auto">
                    <div>
                      <Form.Select
                        value={selectedEntries}
                        onChange={(event) => handleEntriesChange(event)}
                      >
                        <option value={20}>20</option>
                        <option value={40}>40</option>
                        <option value={60}>60</option>
                      </Form.Select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="p-3 bg-white">
                      <table className="table table-hover table-sm table-responsive">
                        <thead>
                          <tr className="fw-bold">
                            <td>Name</td>
                            <td className="text-black">By</td>
                            <td className="text-black">Leads</td>
                            <td className="text-black">Status</td>
                            <td className="text-black"></td>
                          </tr>
                        </thead>
                        {/* <tbody>
                          {campaigns.map((row, index) => (
                            <tr key={index}>
                              <td>{row.campaignName} </td>
                              <td>
                                {row.user.firstName} {row.user.lastName}
                              </td>
                              <td>{row.recordCount}</td>
                              <td>{row.status}</td>
                              <td>
                                <Link
                                  to={`/account/campaigns/${row._id}`}
                                  className="mb-3 text-decoration-none dashboardlink "
                                >
                                  <i className="bi bi-eye-fill"></i>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody> */}
                        <tbody>
                          {campaigns.map((row, index) => (
                            <tr key={index}>
                              <td>{row.campaignName}</td>
                              <td>
                                {row.user
                                  ? `${row.user.firstName || ""} ${
                                      row.user.lastName || ""
                                    }`
                                  : "Unknown"}
                              </td>
                              <td>{row.recordCount}</td>
                              <td>{row.status}</td>
                              <td>
                                <Link
                                  to={`/account/campaigns/${row._id}`}
                                  className="mb-3 text-decoration-none dashboardlink "
                                >
                                  <i className="bi bi-eye-fill"></i>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <span className="m-auto d-flex justify-content-start">
                            {recordRange}
                          </span>
                        </div>

                        <div className="col-lg-6 d-flex justify-content-end">
                          <nav aria-label="...">
                            <ul className="pagination">
                              {[...Array(totalPages)].map((_, page) => (
                                <li
                                  key={page}
                                  className={`page-item ${
                                    currentPage === page + 1 ? "active" : ""
                                  }`}
                                >
                                  <button
                                    className="page-link"
                                    onClick={() =>
                                      handlePageChange(
                                        page + 1,
                                        selectedEntries
                                      )
                                    }
                                  >
                                    {page + 1}
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NewCampaign;
