import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col, Spinner } from "react-bootstrap";
import { getCookie } from "../../../utils/auth";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import Navbar from "../../../Components/Navbar";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Loading from "../../../images/loading.gif";


const AddUser = () => {
  const [toggle, setToggle] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const Toggle = () => {
    setToggle(!toggle);
  };

  const navigate = useNavigate();

  const createUser = () => {
    setLoading(true);
    const payload = {
      firstName,
      lastName,
      companyName,
      jobTitle,
      email,
      password,
      roleId: selectedRoleId,
    };

    axios
      .post(`${baseURL}user/createUser`, payload, {
        headers: { ...headers },
      })
      .then((res) => {
        console.log(res);
        Swal.fire("Submitted!", "User successfully Added!", "success");
        setFirstName("");
        setLastName("");
        setCompanyName("");
        setJobTitle("");
        setEmail("");
        setPassword("");
        fetchData();
        setIsLoading(false);
        navigate("/account/user");
      })
      .catch((err) => {
        console.error("Error:", err);

        toast(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const getRolesDropdown = () => {
      axios
        .get(`${baseURL}role/getRolesDropdown`, {
          headers: { ...headers },
        })
        .then((res) => {
          // console.log(res);
          const responseRoles = res.data.data || [];
          setRoles(responseRoles);
        })
        .catch((err) => {
          console.error("Error:", err);
        });
    };
    getRolesDropdown();
  }, []);

  const fetchData = () => {};

  return (
    <>
      <div className="container-fluid min-vh-100 bg-light">
        <div className="row ">
          {toggle && (
            <div className="col-lg-2 p-3 bg-white vh-100 position-fixed">
              <Sidebar />
            </div>
          )}
          {toggle && <div className="col-lg-2"></div>}
          <div className="col">
            <Navbar Toggle={Toggle} />
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center pt-5">
                {/* <Spinner animation="grow" variant="warning" /> */}
                <img src={Loading} alt="" width={25} />
              </div>
            ) : (
              <div className="container">
              <Link to="/account/user" className="mb-3 text-decoration-none dashboardlink fw-bold"><i class="bi bi-arrow-left-circle-fill me-2"></i>Back</Link>

                <h5 className="mt-4">New User</h5>
                <div className="row mb-3">
                  <div className="col-lg-12 d-flex justify-content-center align-items-center">
                    <div className="col-lg-6">
                      <div className=" bg-white rounded-3 p-3">
                        <Form>
                          <Form.Control
                            className="mb-3"
                            type="text"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                          <Form.Control
                            className="mb-3"
                            type="text"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                          <Form.Control
                            className="mb-3"
                            type="text"
                            placeholder="Company Name"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                          />
                          <Form.Control
                            className="mb-3"
                            type="text"
                            placeholder="JobTitle"
                            value={jobTitle}
                            onChange={(e) => setJobTitle(e.target.value)}
                          />
                          <Form.Control
                            className="mb-3"
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <Form.Control
                            className="mb-3"
                            type="text"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {/* <Form.Label>User Role</Form.Label> */}
                          <Form.Select
                            className="mb-3"
                            value={selectedRoleId}
                            onChange={(e) => setSelectedRoleId(e.target.value)}
                          >
                            <option value="">Select Role</option>
                            {roles.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.roleName}
                              </option>
                            ))}
                          </Form.Select>
                          <Button
                            variant="primary"
                            onClick={createUser}
                            disabled={loading}
                          >
                            {loading ? "Submitting..." : "Submit"}
                          </Button>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
