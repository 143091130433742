import {create} from 'zustand'
import {persist} from "zustand/middleware"

let appStore = (set) =>({
rows:[],
setRows : (rows) => set((state) =>({rows: rows}))
})


appStore = persist(appStore, {name: "crateuser"})
export const useAppStrore = create(appStore)