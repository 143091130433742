import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { getCookie } from "../../../utils/auth";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import Navbar from "../../../Components/Navbar";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const CreateRole = () => {
  const [toggle, setToggle] = useState(true);
  const [roleName, setRoleName] = useState("");
  const [roleId, setroleID] = useState([]);
  const [loading, setLoading] = useState(false);

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const Toggle = () => {
    setToggle(!toggle);
  };

  const navigate = useNavigate();

  const createRole = () => {
    setLoading(true);
    const payload = {
      roleName,
      roleId,
    };

    axios
      .post(`${baseURL}role/createRole`, payload, {
        headers: { ...headers },
      })
      .then((res) => {
        console.log(res);
        Swal.fire("Submitted!", "Role successfully Added!", "success");
        setRoleName("");
        setroleID("");
        navigate("/account/roles");
      })
      .catch((err) => {
        console.error("Error:", err);

        toast(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container fluid className="min-vh-100 backgroundColor">
      <Row>
        {toggle && (
          <Col md={2} className="bg-white vh-100 position-fixed">
            <Sidebar />
          </Col>
        )}
        {toggle && <Col md={2}></Col>}
        <Col>
          <div className="px-3">
            <Navbar Toggle={Toggle} />
            <Container className="d-flex mt-5 responsive-Roles">
              <div className="shadow p-4">
                <h5 className="text-center">Add Role</h5>
                <Form>
                  <Row className="mb-2">
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        value={roleName}
                        onChange={(e) => setRoleName(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder="Id"
                        value={roleId}
                        onChange={(e) => setroleID(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Button
                        variant="primary"
                        onClick={createRole}
                        className="d-flex m-auto"
                        disabled={loading}
                      >
                        {loading ? "Submitting..." : "Submit"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateRole;
