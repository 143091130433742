import React from "react";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/collapse";
import { NavItem } from "react-bootstrap";
import { logOut } from "../utils/auth";
import cookie from "js-cookie";
import { getCookie } from "../utils/auth";
import axios from "axios";
import { baseURL } from "../utils/constant";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";

function NavBar({ Toggle }) {
  const navigate = useNavigate();
  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
  });

  const [isLoading, setIsLoading] = useState(true);

  const handleLogOut = () => {
    const bearerToken = cookie.get("bearerToken");
    logOut(bearerToken);
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`${baseURL}user/getUserDetailsByUserId`, {
          headers: { ...headers },
        })
        .then((response) => {
          const { firstName, lastName } = response.data.data;
          setUser({
            firstName,
            lastName,
          });
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response && err.response.status === 401) {
            return navigate("/");
          }
          setIsLoading(false);
        });
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <nav className="navbar navbar-expand-sm navbar-dark bg-transparent mb-2">
      <i
        className="navbar-brand bi bi-justify-left fs-4 text-black"
        onClick={Toggle}
        style={{ cursor: "pointer" }}
      ></i>
      <button
        className="navbar-toggler d-lg-none"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsibleNavId"
        aria-controls="collapsibleNavId"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i className="bi bi-justify"></i>
      </button>
      <div className="collapse navbar-collapse" id="collapsibleNavId">
        <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
          <li className="nav-item dropdown ">
            <NavItem
              className="nav-link dropdown-toggle text-black fw-bold"
              href="#"
              id="dropdownId"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {user.firstName} {user.lastName}
            </NavItem>
            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownId">
              <a className="dropdown-item" href="#">
                Profile
              </a>
              <a className="dropdown-item fw-bold" href="#">
                Setting
              </a>
              <NavItem className="dropdown-item fw-bold" onClick={handleLogOut}>
                Logout
              </NavItem>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}
export default NavBar;
