import React, { useState, useEffect } from "react";
import { getCookie } from "../../utils/auth";
import axios from "axios";
import { baseURL } from "../../utils/constant";
import { toast } from "react-toastify";
import { Spinner, Form, Button } from "react-bootstrap";
import { useAppStrore } from "../../appStore";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Navbar from "../../Components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import Loading from "../../images/loading.gif";


export default function User() {
  const [toggle, setToggle] = useState(true);
  // const [rowsPerPage, setRowsPerPage] = useState(10);

  const setRows = useAppStrore((state) => state.setRows);
  const [plans, setPlans] = useState([]);
  const [recordRange, setRecordRange] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedEntries, setSelectedEntries] = useState(5);
  const [search, setSearch] = useState("");
  const [selectedEntriesPerPage, setSelectedEntriesPerPage] = useState(5);

  const [recordTotal, setRecordTotal] = useState({ recordsFiltered: 0 });
  const rows = useAppStrore((state) => state.rows);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const Toggle = () => {
    setToggle(!toggle);
  };

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const tableData = (page, start, length, search) => {
    const payload = {
      page: page,
      start: start,
      length: length,
      columns: [
        {
          data: "campaignName",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "recordCount",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "userId",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "asc",
        },
      ],
      search: {
        value: search,
        regex: false,
      },
    };

    axios
      .post(`${baseURL}plan/getDataTableForPlanList`, payload, {
        headers: { ...headers },
      })
      .then((response) => {
        // console.log(response);
        setIsLoading(false);
        setPlans(response.data.data);
        setRecordRange(response.data.recordsRange);
        setTotalPages(response.data.totalPages);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Error:", err);
        toast(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  useEffect(() => {
    tableData(1, 0, 5, "");
  }, []);

  const handlePageChange = (page, selectedLength) => {
    setSelectedEntries(selectedLength); // Update selectedEntries state
    setCurrentPage(page); // Update currentPage state
    tableData(page, (page - 1) * selectedLength, selectedLength, search); // Call tableData with the new page and other parameters
  };

  const handleEntriesChange = (event) => {
    const selectedLength = parseInt(event.target.value);
    handlePageChange(1, selectedLength); // Reset to first page when changing number of entries
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearch(query);
    setCurrentPage(1);
    if (query.trim() !== "") {
      tableData(1, 0, selectedEntries, query); // Pass selectedEntries instead of a fixed value
    } else {
      tableData(1, 0, selectedEntries, ""); // Pass selectedEntries instead of a fixed value
    }
  };

  const handleEdit = (planId) => {
    console.log("Plan ID:", planId); // Log the plan ID to the console
    // Navigate to the EditPlan page with the plan ID
    navigate(`/account/plans/editPlan/${planId}`);
  };

  return (
    <>
      <div className="container-fluid min-vh-100 bg-light">
        <div className="row ">
          {toggle && (
            <div className="col-lg-2 p-3 bg-white vh-100 position-fixed">
              <Sidebar />
            </div>
          )}
          {toggle && <div className="col-lg-2"></div>}
          <div className="col">
            <Navbar Toggle={Toggle} />
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center pt-5">
                {/* <Spinner animation="grow" variant="warning" /> */}
                <img src={Loading} alt="" width={25} />
              </div>
            ) : (
              <div>
                <div className="row mb-3">
                  <Link
                    to="/account/dashboard"
                    className="mb-3 text-decoration-none dashboardlink fw-bold"
                  >
                    <i class="bi bi-arrow-left-circle-fill me-2"></i>Back
                  </Link>

                  <div className="col-lg-8 my-auto">
                    <h5 className="mt-4">Plans</h5>
                  </div>
                  <div className="col-lg-2 ms-auto text-end">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => navigate("./CreatePlan")}
                    >
                      New Plan
                    </button>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div className="col-lg-1 ms-auto">
                    <div>
                      <Form.Select
                        value={selectedEntries}
                        onChange={(event) => handleEntriesChange(event)}
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                      </Form.Select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="p-3 bg-white">
                      <table className="table table-hover table-sm table-responsive">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Details</th>
                            <th>Duration</th>
                            <th>Credits</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {plans.map((row, index) => (
                            <tr key={row._id}>
                              <td>{row.planName}</td>
                              <td>{row.planPrice}</td>
                              {/* <td>{row.planDetails}</td> */}
                              <td>
                                <p>
                                  <FaCheck />
                                  Voice Validation
                                  <br />
                                  <FaCheck /> LinkedIn Validation
                                  <br />
                                  <FaCheck />
                                  Email Validation
                                  <br />
                                  <FaCheck />
                                  Dupe Check
                                  <br />
                                  <FaCheck />
                                  Real-Time Report Access
                                  <br />
                                  <FaCheck /> QA Assurance
                                  <br />
                                </p>
                              </td>
                              <td>{row.planDuration} days</td>
                              <td>{row.planCredits}</td>
                              <td>
                                <Button
                                  variant="outline-primary btn-sm"
                                  onClick={() => handleEdit(row._id)}
                                >
                                  Edit
                                </Button>{" "}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="row">
                        <div className="col-lg-6 my-auto">{recordRange}</div>
                        <div className="col-lg-6 d-flex justify-content-end">
                          <nav aria-label="...">
                            <ul className="pagination">
                              {[...Array(totalPages)].map((_, page) => (
                                <li
                                  key={page}
                                  className={`page-item ${
                                    currentPage === page + 1 ? "active" : ""
                                  }`}
                                >
                                  <button
                                    className="page-link"
                                    onClick={() =>
                                      handlePageChange(
                                        page + 1,
                                        selectedEntries
                                      )
                                    }
                                  >
                                    {page + 1}
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
