import React, { useState } from "react";
import { Button, Container, Form, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../utils/auth";
import axios from "axios";
import { baseURL } from "../../utils/constant";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Navbar from "../../Components/Navbar"; // Added import for Navbar
import Loading from "../../images/loading.gif";


const CreatePlanUser = () => {
  const [toggle, setToggle] = useState(true);
  const [planName, setPlanName] = useState("");
  const [planPrice, setPlanPrice] = useState("");
  const [planDetails, setPlanDetails] = useState("");
  const [planDuration, setPlanDuration] = useState("");
  const [planCredits, setPlanCredits] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const navigate = useNavigate();

  const createUser = () => {
    setLoading(true);
    const payload = {
      planName,
      planPrice,
      planDetails,
      planDuration,
      planCredits,
    };

    axios
      .post(`${baseURL}plan/createPlan`, payload, {
        headers: { ...headers },
      })
      .then((res) => {
        console.log(res);
        Swal.fire("Submitted!", "User successfully Added!", "success");
        setPlanName("");
        setPlanPrice("");
        setPlanDetails("");
        setPlanDuration("");
        setPlanCredits("");
        navigate("/account/plans");
      })
      .catch((err) => {
        console.error("Error:", err);
        toast(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const Toggle = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <Container fluid className="min-vh-100 backgroundColor">
        <Row>
          {toggle && (
            <Col md={2} className="bg-white vh-100 position-fixed">
              <Sidebar />
            </Col>
          )}
          {toggle && <Col md={2}></Col>}
          <Col>
            <div className="px-3">
              <Navbar Toggle={Toggle} />
              <Container fluid>
                {isLoading ? (
                  <div className="d-flex justify-content-center align-items-center pt-5">
                    {/* <Spinner animation="grow" variant="warning" /> */}
                    <img src={Loading} alt="" width={25} />
                  </div>
                ) : (
                  <Container className="d-flex justify-content-center align-items-center vh-100">
                    <div className="shadow p-4">
                      <h5 className="text-center mb-4">Add Plan</h5>
                      <Form>
                        <Row className="mb-2">
                          <Col>
                            <Form.Control
                              type="text"
                              placeholder="Plan Name"
                              value={planName}
                              onChange={(e) => setPlanName(e.target.value)}
                            />
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              placeholder="Plan Price"
                              value={planPrice}
                              onChange={(e) => setPlanPrice(e.target.value)}
                            />
                          </Col>
                        </Row>

                        <Row className="mb-2">
                          <Col>
                            <Form.Control
                              type="text"
                              placeholder="Plan Details"
                              value={planDetails}
                              onChange={(e) => setPlanDetails(e.target.value)}
                            />
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              placeholder="Plan Duration"
                              value={planDuration}
                              onChange={(e) => setPlanDuration(e.target.value)}
                            />
                          </Col>
                        </Row>

                        <Row className="mb-2">
                          <Col>
                            <Form.Control
                              type="text"
                              placeholder="Plan Credits"
                              value={planCredits}
                              onChange={(e) => setPlanCredits(e.target.value)}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col className="m-auto d-flex justify-content-center">
                            <Button
                              variant="primary"
                              onClick={createUser}
                              disabled={loading}
                            >
                              {loading ? "Submitting..." : "Submit"}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Container>
                )}
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreatePlanUser;
