import React, { useState, useEffect } from "react";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import Navbar from "../../../Components/Navbar";
import { Col, Form, Pagination, Row } from "react-bootstrap";
import { baseURL } from "../../../utils/constant";
import axios from "axios";
import { getCookie } from "../../../utils/auth";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loading from "../../../images/loading.gif";

const Payment = () => {
  const [toggle, setToggle] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedEntriesPerPage, setSelectedEntriesPerPage] = useState(50);
  const [recordTotal, setRecordTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState({
    totalPayments: 0,
    completePayments: 0,
    pendingPayments: 0,
    cancelledPayments: 0,
  });

  const bearerToken = getCookie("bearerToken");

  const headers = React.useMemo(() => ({
    Authorization: `Bearer ${bearerToken}`,
  }), [bearerToken]);

  const Toggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    axios
      .get(`${baseURL}dashboard/getStatusCountForPayment`, { headers })
      .then((response) => {
        const {
          cancelledPayments,
          completePayments,
          pendingPayments,
          totalPayments,
        } = response.data.data;
        setCount({
          cancelledPayments,
          completePayments,
          pendingPayments,
          totalPayments,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [headers]);


  useEffect(() => {
    tableData(currentPage, 0, selectedEntriesPerPage, searchTerm);
  }, [currentPage, selectedEntriesPerPage, searchTerm]);

  const tableData = (page, start, length, search) => {
    const payload = {
      page,
      start,
      length,
      columns: [
        {
          data: "paymentId",
          name: "",
          searchable: true,
          orderable: true,
          search: { value: searchTerm, regex: false },
        },
        {
          data: "status",
          name: "",
          searchable: true,
          orderable: true,
          search: { value: searchTerm, regex: false },
        },
        {
          data: "currency",
          name: "",
          searchable: true,
          orderable: true,
          search: { value: searchTerm, regex: false },
        },
      ],
      order: [{ column: 0, dir: "desc" }],
      search: { value: search, regex: false },
    };

    axios
      .post(`${baseURL}payment/getDataTableForPaymentList`, payload, {
        headers,
      })
      .then((response) => {
        const responseData = response.data.data || [];
        setPaymentData(responseData);
        setRecordTotal(response.data.recordsTotal);

        const totalPages = Math.ceil(response.data.recordsTotal / length);
        setTotalPages(totalPages);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Error:", err);
        toast(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${day < 10 ? "0" + day : day} ${monthNames[monthIndex]} ${year}`;
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleEntriesPerPageChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    setSelectedEntriesPerPage(selectedValue);
    setCurrentPage(1);
  };

  const {
    totalPayments,
    completePayments,
    pendingPayments,
    cancelledPayments,
  } = count;

  return (
    <div className="container-fluid min-vh-100 bg-light">
      <div className="row">
        {toggle && (
          <Col lg={2} className="p-3 bg-white vh-100 position-fixed">
            <Sidebar />
          </Col>
        )}
        {toggle && <Col lg={2}></Col>}
        <Col lg={toggle ? 10 : 12}>
          <Navbar Toggle={Toggle} />
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <img src={Loading} alt="" width={25} />
            </div>
          ) : (
            <div>
              <div className="row mb-3">
                <Link
                  to="/account/dashboard"
                  className="mb-3 text-decoration-none dashboardlink fw-bold"
                >
                  <i className="bi bi-arrow-left-circle-fill me-2"></i>Back
                </Link>
                <div className="col-lg-8 my-auto">
                  <h5 className="mt-4 fw-bold">Payments</h5>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-lg-3">
                  <div className="p-3 bg-white d-flex justify-content-start align-items-center rounded border_bottom">
                    <div>
                      <h5>{totalPayments}</h5>
                      <p className="mb-0 fw-bold">Total</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="p-3 bg-white d-flex justify-content-start align-items-center rounded border_bottom">
                    <div>
                      <h5>{completePayments}</h5>
                      <p className="mb-0 fw-bold">Paid</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="p-3 bg-white d-flex justify-content-start align-items-center rounded border_bottom">
                    <div>
                      <h5>{pendingPayments}</h5>
                      <p className="mb-0 fw-bold">Pending</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="p-3 bg-white d-flex justify-content-start align-items-center rounded border_bottom">
                    <div>
                      <h5>{cancelledPayments}</h5>
                      <p className="mb-0 fw-bold">Cancelled</p>
                    </div>
                  </div>
                </div>
              </div>
              <Row className="mb-3 mt-5">
                <Col lg={8}>
                  <Form.Control
                    type="text"
                    className="form-control ms-4 rounded-2"
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Col>
                <Col lg={1} />
                <Col lg={2}>
                  <div>
                    <Form.Select
                      value={selectedEntriesPerPage}
                      onChange={handleEntriesPerPageChange}
                    >
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Select>
                  </div>
                </Col>
              </Row>
              <div className="row">
                <div className="col-lg-12">
                  <div className="p-3 bg-white">
                    <table className="table table-hover table-sm table-responsive">
                      <thead>
                        <tr className="fw-bold">
                          <td>Payment ID</td>
                          <td>Name</td>
                          <td>Plan Name</td>
                          <td>Amount</td>
                          <td>Currency</td>
                          <td>Status</td>
                          <td>Date</td>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentData.map((payment) => (
                         <tr key={payment.paymentId}>
                            <td>{payment.paymentId}</td>
                            <td>
                              {payment.userId?.firstName || ""}{" "}
                              {payment.userId?.lastName || ""}
                            </td>
                            <td>{payment.planId.planName}</td>
                            <td>{payment.amount}</td>
                            <td>{payment.currency.toUpperCase()}</td>
                            <td>
                              {payment.status === "CREATED"
                                ? "Pending"
                                : payment.status === "Paid"
                                ? "Complete"
                                : "Cancelled"}
                            </td>
                            <td>{formatDate(payment.createdAt)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Pagination>
                  <Pagination.Prev
                    onClick={() =>
                      setCurrentPage((prev) => (prev <= 1 ? prev : prev - 1))
                    }
                    disabled={currentPage === 1}
                  />
                  {Array.from({ length: totalPages }, (_, i) => {
                    if (
                      i + 1 === 1 ||
                      i + 1 === totalPages ||
                      Math.abs(currentPage - (i + 1)) <= 1
                    ) {
                      return (
                        <Pagination.Item
                          key={i + 1}
                          active={i + 1 === currentPage}
                          onClick={() => paginate(i + 1)}
                        >
                          {i + 1}
                        </Pagination.Item>
                      );
                    } else if (
                      (i === 0 && currentPage > 2) ||
                      (i === totalPages - 1 && currentPage < totalPages - 1)
                    ) {
                      return <Pagination.Ellipsis key={i} />;
                    }
                    return null;
                  })}
                  <Pagination.Next
                    onClick={() =>
                      setCurrentPage((prev) =>
                        prev >= totalPages ? prev : prev + 1
                      )
                    }
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            </div>
          )}
        </Col>
      </div>
    </div>
  );
}

export default Payment;

// import React, { useState, useEffect } from "react";
// import Sidebar from "../../../Components/Sidebar/Sidebar";
// import Navbar from "../../../Components/Navbar";
// import { Form, Pagination, Spinner } from "react-bootstrap";
// import { baseURL } from "../../../utils/constant";
// import axios from "axios";
// import { getCookie } from "../../../utils/auth";
// import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
// import Loading from "../../../images/loading.gif";

// function Payment() {
//   const [toggle, setToggle] = useState(true);
//   const [isLoading, setIsLoading] = useState(false);
//   const [paymentData, setPaymentData] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [selectedEntries, setSelectedEntries] = useState(5);
//   const [search, setSearch] = useState("");
//   const [campaginCount, setCampaginCount] = useState({});
//   const [totalPages, setTotalPages] = useState(1);
//   const [recordRange, setRecordRange] = useState([]);

//   const [recordTotal, setRecordTotal] = useState(0);

//   const [count, setCount] = useState({
//     totalPayments: 0,
//     completePayments: 0,
//     pendingPayments: 0,
//     cancelledPayments: 0,
//   });

//   const bearerToken = getCookie("bearerToken");
//   const headers = {
//     Authorization: `Bearer ${bearerToken}`,
//   };

//   const Toggle = () => {
//     setToggle(!toggle);
//   };

//   //get data for total cancel pending etc...
//   useEffect(() => {
//     axios
//       .get(`${baseURL}dashboard/getStatusCountForPayment`, {
//         headers: { ...headers },
//       })
//       .then((response) => {
//         console.log(response);
//         const {
//           cancelledPayments,
//           completePayments,
//           pendingPayments,
//           totalPayments,
//         } = response.data.data;
//         setCount({
//           cancelledPayments,
//           completePayments,
//           pendingPayments,
//           totalPayments,
//         });
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }, []);

//   const {
//     totalPayments,
//     completePayments,
//     pendingPayments,
//     cancelledPayments,
//   } = count;
//   const totalCompleted = paymentData.filter(
//     (payment) => payment.status === "Paid"
//   ).length;
//   const totalPending = paymentData.filter(
//     (payment) => payment.status === "requires_payment_method"
//   );
//   const totalCanceled = paymentData.filter(
//     (payment) => payment.status === "Cancel"
//   ).length;

//   function formatDate(dateString) {
//     const date = new Date(dateString);
//     const day = date.getDate();
//     const monthNames = [
//       "January",
//       "February",
//       "March",
//       "April",
//       "May",
//       "June",
//       "July",
//       "August",
//       "September",
//       "October",
//       "November",
//       "December",
//     ];
//     const monthIndex = date.getMonth();
//     const year = date.getFullYear();
//     return `${day < 10 ? "0" + day : day} ${monthNames[monthIndex]} ${year}`;
//   }

//   useEffect(() => {
//     axios
//       .get(`${baseURL}dashboard/getStatusCountForCampaign`, {
//         headers: { ...headers },
//       })
//       .then((response) => {
//         // console.log(response);
//         setIsLoading(false);
//         const {
//           newCampaigns,
//           totalCampaigns,
//           completeCampaigns,
//           inProgressCampaigns,
//         } = response.data.data;

//         setCampaginCount({
//           newCampaigns,
//           totalCampaigns,
//           completeCampaigns,
//           inProgressCampaigns,
//         });
//       })
//       .catch((err) => {
//         toast(err?.response?.data?.message, {
//           position: "bottom-left",
//           autoClose: 5000,
//           hideProgressBar: true,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         });
//       });
//   }, []);

//   const tableData = (page, start, length, search) => {
//     const payload = {
//       page: page,
//       start: start,
//       length: length,
//       columns: [
//         {
//           data: "paymentId",
//           name: "",
//           searchable: true,
//           orderable: true,
//           search: {
//             value: searchTerm,
//             regex: false,
//           },
//         },
//         {
//           data: "status",
//           name: "",
//           searchable: true,
//           orderable: true,
//           search: {
//             value: searchTerm,
//             regex: false,
//           },
//         },
//         {
//           data: "currency",
//           name: "",
//           searchable: true,
//           orderable: true,
//           search: {
//             value: searchTerm,
//             regex: false,
//           },
//         },
//       ],
//       order: [
//         {
//           column: 0,
//           dir: "dec",
//         },
//       ],
//       search: {
//         value: search,
//         regex: false,
//       },
//     };

//     axios
//       .post(`${baseURL}payment/getDataTableForPaymentList`, payload, {
//         headers: headers,
//       })
//       .then((response) => {
//         const responseData = response.data;
//         setPaymentData(responseData.data);
//         setRecordTotal(responseData.recordsTotal);
//         setRecordRange(responseData.recordRange);

//         // Calculate total pages
//         const totalPages = Math.ceil(responseData.recordsTotal / length);
//         setTotalPages(totalPages);
//       })
//       .catch((err) => {
//         setIsLoading(false);
//         console.error("Error:", err);
//         toast(err?.response?.data?.message, {
//           position: "bottom-left",
//           autoClose: 5000,
//           hideProgressBar: true,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         });
//       });
//   };

//   useEffect(() => {
//     tableData(1, 0, 5, "");
//   }, []);

//   const handlePageChange = (page, selectedLength) => {
//     setSelectedEntries(selectedLength);
//     setCurrentPage(page); // Update currentPage state
//     const startIndex = (page - 1) * selectedLength;
//     tableData(page, startIndex, selectedLength, search); // Call tableData with the new page and other parameters
//   };

//   const handleEntriesChange = (event) => {
//     const selectedLength = parseInt(event.target.value);
//     handlePageChange(1, selectedLength); // Reset to first page when changing number of entries
//   };

//   const handleSearchChange = (event) => {
//     const query = event.target.value;
//     setSearch(query);
//     setCurrentPage(1);
//     if (query.trim() !== "") {
//       tableData(1, 0, selectedEntries, query); // Pass selectedEntries instead of a fixed value
//     } else {
//       tableData(1, 0, selectedEntries, ""); // Pass selectedEntries instead of a fixed value
//     }
//   };

//   return (
//     <>
//       <div className="container-fluid min-vh-100 bg-light">
//         <div className="row ">
//           {toggle && (
//             <div className="col-lg-2 p-3 bg-white vh-100 position-fixed">
//               <Sidebar />
//             </div>
//           )}
//           {toggle && <div className="col-lg-2"></div>}
//           <div className="col">
//             <Navbar Toggle={Toggle} />
//             {isLoading ? (
//               <div className="d-flex justify-content-center align-items-center pt-5">
//                 {/* <Spinner animation="grow" variant="warning" /> */}
//                 <img src={Loading} alt="" width={25} />
//               </div>
//             ) : (
//               <div>
//                 <div className="row mb-3">
//                   <Link
//                     to="/account/dashboard"
//                     className="mb-3 text-decoration-none dashboardlink fw-bold"
//                   >
//                     <i class="bi bi-arrow-left-circle-fill me-2"></i>Back
//                   </Link>

//                   <div className="col-lg-8 my-auto">
//                     <h5 className="mt-4 fw-bold">Payments</h5>
//                   </div>
//                 </div>

//                 <div className="row mb-3">
//                   <div className="col-lg-3">
//                     <div className="p-3 bg-white d-flex justify-content-start align-items-center rounded border_bottom">
//                       <div>
//                         <h5>{totalPayments}</h5>
//                         <p className="mb-0 fw-bold">Total</p>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-lg-3">
//                     <div className="p-3 bg-white d-flex justify-content-start align-items-center rounded border_bottom">
//                       <div>
//                         <h5>{completePayments}</h5>
//                         <p className="mb-0 fw-bold ">Paid</p>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-lg-3">
//                     <div className="p-3 bg-white d-flex justify-content-start align-items-center rounded border_bottom">
//                       <div>
//                         <h5>{pendingPayments}</h5>
//                         <p className="mb-0 fw-bold">Pending</p>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-lg-3">
//                     <div className="p-3 bg-white d-flex justify-content-start align-items-center rounded border_bottom">
//                       <div>
//                         <h5>{cancelledPayments}</h5>
//                         <p className="mb-0 fw-bold">Canceled</p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 {/* Render payment data here */}
//                 <div className="row mb-3">
//                   <div className="col-lg-8">
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Search"
//                       value={search}
//                       onChange={handleSearchChange}
//                     />
//                   </div>
//                   <div className="col-lg-1 ms-auto">
//                     <div>
//                       <Form.Select
//                         value={selectedEntries}
//                         onChange={(event) => handleEntriesChange(event)}
//                       >
//                         <option value={5}>5</option>
//                         <option value={10}>10</option>
//                         <option value={20}>20</option>
//                       </Form.Select>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="row">
//                   <div className="col-lg-12">
//                     <div className="p-3 bg-white">
//                       <table className="table table-hover table-sm table-responsive">
//                         <thead>
//                           <tr className="fw-bold ">
//                             <td>Payment ID</td>
//                             <td>Name</td>
//                             <td>Plan Name</td>
//                             <td>Amount</td>
//                             <td>Curruncy</td>
//                             <td>Status</td>
//                             <td>Date</td>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {paymentData.map((payment) => (
//                             <tr key={payment.id}>
//                               <td>{payment.paymentId}</td>
//                               <td>
//                                 {payment.userId.firstName} {payment.userId.lastName}
//                               </td>
//                               <td>{payment.planId.planName}</td>
//                               <td>{payment.amount}</td>
//                               <td>{payment.currency.toUpperCase()}</td>
//                               <td>
//                                 {payment.status === "requires_payment_method"
//                                   ? "Pending"
//                                   : payment.status === "Paid"
//                                   ? "Complete"
//                                   : "Canceled"}
//                               </td>
//                               <td>{formatDate(payment.createdAt)}</td>
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                       <div className="row">
//                         <div className="col-lg-6 my-auto">
//                           <span className="m-auto d-flex justify-content-start">
//                             {recordRange}
//                           </span>
//                         </div>
//                         <div className="col-lg-6 d-flex justify-content-end cursorPointer">
//                           <nav aria-label="...">
//                             <ul className="pagination">
//                               {[...Array(totalPages)].map((_, page) => (
//                                 <li
//                                   key={page}
//                                   className={`page-item ${
//                                     currentPage === page + 1 ? "active" : ""
//                                   }`}
//                                 >
//                                   <button
//                                     className="page-link"
//                                     onClick={() =>
//                                       handlePageChange(
//                                         page + 1,
//                                         selectedEntries
//                                       )
//                                     }
//                                   >
//                                     {page + 1}
//                                   </button>
//                                 </li>
//                               ))}
//                             </ul>
//                           </nav>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Payment;
