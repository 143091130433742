import React, { useEffect, useState } from "react";
import NavBar from "../../../Components/Navbar";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import { Link, useParams } from "react-router-dom";
import Loading from "../../../images/loading.gif";


function EditUser() {
  const [toggle, setToggle] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { _id } = useParams();

  // console.log(_id);
  const Toggle = () => {
    setToggle(!toggle);
  };
  return (
    <>
      <div className="container-fluid min-vh-100 bg-light">
        <div className="row">
          {toggle && (
            <div className="col-lg-2 p-3 bg-white vh-100 position-fixed">
              <Sidebar />
            </div>
          )}
          {toggle && <div className="col-lg-2"></div>}
          <div className="col">
            <NavBar Toggle={Toggle} />
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center pt-5">
                {/* <Spinner animation="grow" variant="warning" /> */}
                <img src={Loading} alt="" width={25} />
              </div>
            ) : (
              <div>
                <Container>
              <Link to="/account/user" className="mb-3 text-decoration-none dashboardlink fw-bold"><i class="bi bi-arrow-left-circle-fill me-2"></i>Back</Link>

                  <h3>Edit User</h3>
                  <Row>
                    <Col
                      lg={12}
                      className=" d-flex justify-content-center align-items-center pt-5"
                    >
                      <Col lg={6} className="bg-white p-3">
                        <Form>
                          <Form.Group className="mb-3" controlId="firstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter first name"
                            />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="lastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter last name"
                            />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter email"
                            />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="role">
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter role"
                            />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="status">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter status"
                            />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="credits">
                            <Form.Label>Credits</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter credits"
                            />
                          </Form.Group>
                          <Button variant="primary" type="submit">
                            Update
                          </Button>
                        </Form>
                      </Col>
                    </Col>
                  </Row>
                </Container>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default EditUser;
